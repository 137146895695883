<template>
  <div>
    <form-wizard
      @on-complete="doSubmitFinish"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      ref="formWizard"
      title=""
      subtitle=""
    >
      <tab-content icon="ti-user">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
          :disabled="isCompleted"
        >
          <!-- Template to download for Ethics Committee Guidelines-->
          <app-template-view-file-page
            templateType="projectReport"
          ></app-template-view-file-page>

          <el-form-item
            :label="fields.reportFile.label"
            :prop="fields.reportFile.name"
            :required="fields.reportFile.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.reportFile.max"
                :storage="fields.reportFile.storage"
                :formats="fields.reportFile.formats"
                :permissions="fields.reportFile.permissions"
                v-model="model[fields.reportFile.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.internalReportFile.label"
            :prop="fields.internalReportFile.name"
            :required="fields.internalReportFile.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.internalReportFile.max"
                :storage="fields.internalReportFile.storage"
                :formats="fields.internalReportFile.formats"
                :permissions="fields.internalReportFile.permissions"
                v-model="model[fields.internalReportFile.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>

          <el-form-item
            :prop="fields.projectDurationExtension.name"
            :required="fields.projectDurationExtension.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-checkbox
                v-model="model[fields.projectDurationExtension.name]"
                >{{
                  fields.projectDurationExtension.label
                }}</el-checkbox
              >
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.newEndDate.label"
            :prop="fields.newEndDate.name"
            :required="fields.newEndDate.required"
            v-if="extensionChecked"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.newEndDate.name]"
              ></el-date-picker>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.grantorsExtensionApprovalFile.label"
            :prop="fields.grantorsExtensionApprovalFile.name"
            :required="fields.grantorsExtensionApprovalFile.required"
            v-if="extensionChecked"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.grantorsExtensionApprovalFile.max"
                :storage="
                  fields.grantorsExtensionApprovalFile.storage
                "
                :formats="
                  fields.grantorsExtensionApprovalFile.formats
                "
                :permissions="
                  fields.grantorsExtensionApprovalFile.permissions
                "
                v-model="
                  model[fields.grantorsExtensionApprovalFile.name]
                "
              ></app-file-upload>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <!-- Wzard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            ><app-i18n code="common.previous"></app-i18n
          ></wizard-button>
        </div>
        <div class="wizard-footer-right">
          <div class="wizard-btn">
            <wizard-button
              v-if="!isCompleted"
              icon="el-icon-fa-floppy-o"
              @click.native="doSubmit"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              @click.native="doSubmitFinish"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="props.isLastStep && !(saveLoading || isCompleted)"
              ><app-i18n code="common.finish"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectReportModel } from '@/modules/project-report/project-report-model';
import Templates from '@/modules/template/components/template-view-file-page';

const { fields } = ProjectReportModel;
const formSchema = new FormSchema([
  fields.id,
  fields.reportFile,
  fields.internalReportFile,
  fields.projectDurationExtension,
  fields.grantorsExtensionApprovalFile,
  fields.newEndDate,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-project-report-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  components: {
    [Templates.name]: Templates,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      isMounted: false,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    this.isMounted = true;
  },

  watch: {
    record: function() {
      this.doUpdateModel();
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'projectReport/form/doSetFormModel',
          this.model,
        );
      },
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },

    isCompleted() {
      return this.model.status === 'completed';
    },

    extensionChecked() {
      return this.model.projectDurationExtension;
    },
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    async doSubmit() {
      await this.validateForm();

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    async doSubmitFinish() {
      await this.validateForm();
      this.model.status = 'completed';
      return this.doSubmit();
    },

    async validateForm() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
