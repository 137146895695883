<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-report-form
      style="padding: 25px"
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      @submit="doSubmit"
      v-if="!findLoading"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectReportForm from '@/modules/project-report/components/project-report-form';
import { EventBus } from '@/eventBus';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';
import ProjectStepEnum from '@/shared/enums/projectStep';

export default {
  name: 'app-project-report-form-project-page',

  components: {
    [ProjectReportForm.name]: ProjectReportForm,
  },

  data() {
    return {
      projectStepEnum: ProjectStepEnum.values,
    };
  },

  computed: {
    ...mapGetters({
      record: 'projectReport/form/record',
      findLoading: 'projectReport/form/findLoading',
      saveLoading: 'projectReport/form/saveLoading',
      pendingFiles: 'projectReport/form/pendingFiles',
      status: 'projectReport/form/status',
      projectReportId: 'project/form/projectReportId',
    }),

    isEditing() {
      return !!this.projectReportId;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectReportId);
    } else {
      await this.doNew();
    }
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    if (this.status === 'completed' || to.name.includes('error')) {
      next();
      return;
    }

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'projectReport/form/doFind',
      doNew: 'projectReport/form/doNew',
      doUpdate: 'projectReport/form/doUpdate',
      doCreate: 'projectReport/form/doCreate',
      doUpdateProjectStep: 'project/form/doUpdateProjectStep',
      doSetProjectReportId: 'project/form/doSetProjectReportId',
      doUpdateProject: 'project/form/doUpdate',
      doReloadProject: 'project/form/doReload',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        const reponse = await this.doUpdate(payload);
        if (reponse) {
          if (reponse.status === 'completed') {
            /*  await this.doUpdateProjectStep(
              this.projectStepEnum.completion,
            ); */
            await this.navigateToProjectCompletion();
            await this.doReloadProject();
          }
        }
      } else {
        const id = await this.doCreate(payload.values);
        this.doSetProjectReportId(id);
        this.doUpdateProject();
      }

      if (this.$refs.form) {
        this.$refs.form.doUpdateModel();
      }
      return true;
    },

    navigateToProjectCompletion() {
      EventBus.$emit('navigateToProjectCompletion');
    },
  },
};
</script>

<style>
</style>
